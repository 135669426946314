@let menu = menuItem();

@if (menu.url) {
  <lf-link
    class="menu-item"
    [link]="[menu.url]"
    [routerLinkActiveOptions]="{ exact: menu.url === '/' }">
    @if (menu.iconName; as icon) {
      <abm-icon src="assets/icons/menu/icon-{{ icon }}.svg" />
    }
  </lf-link>
} @else {
  <div
    class="menu-item menu-item-without-link"
    [ngClass]="{ 'menu-item-active': isActive() }">
    @if (menu.iconName; as icon) {
      <abm-icon src="assets/icons/menu/icon-{{ icon }}.svg" />
    }
  </div>
}

@if (mark()) {
  <span class="mark"></span>
}
