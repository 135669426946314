import { IconComponent } from '@abm/icon';
import { ChangeDetectionStrategy, Component, computed, effect, inject, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { TranslatePipe } from '@lf/translate-core';
import { InsightsPermissionFullPipe } from '@permissions/insights-permission-full/insights-permission-full.pipe';
import { InsightService } from '@services/insight/insight.service';
import { CATEGORY } from '@tokens/category.token';
import { InsightItem } from '@type/insight.type';
import { combineLatest, filter, startWith, switchMap, take } from 'rxjs';
import { HeaderInsightItemComponent } from '../header-insight-item/header-insight-item.component';

@Component({
  selector: 'app-header-insights',
  imports: [TranslatePipe, HeaderInsightItemComponent, IconComponent, InsightsPermissionFullPipe],
  templateUrl: './header-insights.component.html',
  styleUrls: ['./header-insights.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderInsightsComponent {
  private insightService = inject(InsightService);

  private category = inject(CATEGORY).value;

  private categoryStream = inject(CATEGORY).asObservable;

  insights = toSignal(
    combineLatest({
      category: this.categoryStream.pipe(
        filter((c) => !!c && c.id > 0),
        filter(Boolean),
      ),
      trigger: this.insightService.updateInsightTrigger.pipe(startWith(true)),
    }).pipe(switchMap(({ category: { id } }) => this.insightService.getInsightList(id))),
    { initialValue: [] },
  );

  emptyState = signal(false);

  newInsights = computed(() => this.insights().filter((i) => !i.is_read));

  recentInsights = computed(() => this.insights().filter((i) => i.is_read));

  constructor() {
    effect(() => this.emptyState.set(!this.insights().length));
  }

  readAll() {
    this.insightService
      .changeAllInsightReadState(this.category()!.id)
      .pipe(take(1))
      .subscribe(() => this.insightService.updateInsightTrigger.next(true));
  }

  changeInsightReadState(insight: InsightItem) {
    this.insightService
      .changeInsightReadState(this.category()!.id, insight.id)
      .pipe(take(1))
      .subscribe(() => this.insightService.updateInsightTrigger.next(true));
  }
}
