@let menu = menuItem();

<div class="header">
  {{ 'main_menu.' + menu.name | t | uppercase }}
</div>
@for (item of menu.childUrls; track item) {
  @if (!item.restrict()) {
    <lf-link
      class="submenu-item lf-link"
      [external]="!!item.external"
      [link]="[item.url]">
      @if (item.iconName) {
        <abm-icon
          width="1.5rem"
          [src]="'assets/icons/icon-' + item.iconName + '.svg'" />
      }
      {{ 'main_menu.sub_menu.' + item.name | t }}

      @let counter = item?.counter?.value();

      @if (counter) {
        <span class="counter">{{ counter }}</span>
      }
    </lf-link>
  }
}
