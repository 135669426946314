@let name = category()?.name;

<div
  class="page-tab"
  [title]="name">
  <abm-icon
    class="category"
    src="assets/icons/cluster/icon-list.svg"
    [height]="18"
    [width]="18"></abm-icon>

  <abm-icon
    class="close"
    src="assets/icons/icon-close-filled.svg"
    (click)="closeTab($event)"
    [height]="18"
    [width]="18"></abm-icon>

  <p class="page-tab-content">{{ name }}</p>
</div>

<div
  class="divider"
  [title]="name"></div>

<div
  class="shadow"
  [title]="name"></div>
