import { SearchFieldComponent } from '@abm/controls';
import { MenuModule } from '@abm/menu';
import { NgClass } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  effect,
  ElementRef,
  HostListener,
  inject,
  output,
  signal,
  viewChild,
  viewChildren,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormsModule } from '@angular/forms';
import { TranslatePipe } from '@lf/translate-core';
import { CATEGORY } from '@tokens/category.token';
import { animationFrameScheduler, fromEvent, tap, throttleTime } from 'rxjs';
import { SearchPipe } from 'src/app/core/pipe/search/search.pipe';
import { AddTabComponent } from '../add-tab/add-tab.component';
import { BaseTabComponent } from '../base-tab/base-tab.component';
import { TabComponent } from '../tab/tab.component';

@Component({
  selector: 'app-page-tabs',
  imports: [
    MenuModule,
    TabComponent,
    AddTabComponent,
    SearchFieldComponent,
    TranslatePipe,
    FormsModule,
    SearchPipe,
    NgClass,
  ],
  templateUrl: './page-tabs.component.html',
  styleUrls: ['./page-tabs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageTabsComponent implements AfterViewInit {
  #element = inject(ElementRef).nativeElement;

  #destroyRef = inject(DestroyRef);

  tabList = viewChildren<BaseTabComponent>('tab');

  tabsContainer = viewChild<ElementRef<HTMLElement>>('tabsContainer');

  roundCorners = output<boolean>();

  category = inject(CATEGORY);

  query = signal('');

  constructor() {
    effect(() => {
      this.category.value() && this.#scrollToActiveTab();
    });
  }

  ngAfterViewInit() {
    this.mouseMoveHandler();
  }

  @HostListener('mouseleave')
  mouseleave() {
    this.roundCorners.emit(!this.tabList()?.at(0)?.isActive());
  }

  @HostListener('wheel', ['$event'])
  wheel(event: WheelEvent) {
    event.preventDefault();
    const el = this.tabsContainer()?.nativeElement;

    if (el) {
      el.scrollLeft += 120 * (event.deltaY / Math.abs(event.deltaY));
    }
  }

  private initRoundCorners() {
    this.roundCorners.emit(!this.tabList()?.at(0)?.isActive());
  }

  private mouseMoveHandler() {
    fromEvent<Event>(this.#element, 'mousemove')
      .pipe(
        throttleTime(50, animationFrameScheduler),
        tap((event: Event) => {
          if (this.tabList()?.at(0)?.isActive()) {
            this.roundCorners.emit(false);
            return;
          }

          if (
            !this.tabList()
              ?.at(0)
              ?.element.contains(event.target as Node | null)
          ) {
            this.roundCorners.emit(true);
            return;
          }

          this.roundCorners.emit(!!this.tabList()?.at(0)?.isActive());
        }),
        takeUntilDestroyed(this.#destroyRef),
      )
      .subscribe();
  }

  #scrollToActiveTab() {
    const tableArr = Array.from(this.tabList());
    const el = tableArr.find((e) => e.isActive())?.element;

    if (el) {
      el.scrollIntoView({ behavior: 'smooth' });
    }

    this.initRoundCorners();
  }
}
