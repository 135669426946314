import { enableProdMode, provideExperimentalZonelessChangeDetection } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import {
  provideRouter,
  withComponentInputBinding,
  withInMemoryScrolling,
  withRouterConfig,
  withViewTransitions,
} from '@angular/router';
import { CORE_PROVIDERS } from 'src/app/core-providers';
import { AppComponent } from './app/app.component';
import { CORE_ROUTES } from './app/core-routes';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
  providers: [
    ...CORE_PROVIDERS,
    provideExperimentalZonelessChangeDetection(),
    provideRouter(
      CORE_ROUTES,
      withRouterConfig({
        onSameUrlNavigation: 'reload',
        paramsInheritanceStrategy: 'always',
      }),
      withComponentInputBinding(),
      withViewTransitions({}),
      withInMemoryScrolling({ scrollPositionRestoration: 'top' }),
    ),
  ],
});
