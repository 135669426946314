import { OverlayModule } from '@angular/cdk/overlay';
import { NgStyle } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, signal } from '@angular/core';
import { PageTabsComponent } from '@modules/tabs/components/page-tabs/page-tabs.component';
import { USER } from '@tokens/user.token';
import { InsightComponent } from '../insight/insight.component';

@Component({
  selector: 'app-header',
  imports: [PageTabsComponent, NgStyle, InsightComponent, OverlayModule],
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent {
  user = inject(USER);

  borderTopLeftRadius = signal(0);

  changeBorderRadius(needRound: boolean) {
    this.borderTopLeftRadius.set(needRound ? 8 : 0);
  }
}
