<div class="menu-top">
  @for (item of topMenu; track item) {
    @if (!item.restrict()) {
      <app-menu-item
        [menuItem]="item"
        [mark]="item?.counter?.value()" />
    }
  }
</div>

<div class="menu-bottom">
  @for (item of bottomMenu; track item) {
    @if (!item.restrict()) {
      <app-menu-item [menuItem]="item" />
    }
  }
</div>
