import {
  ChangeDetectionStrategy,
  Component,
  computed,
  ElementRef,
  HostBinding,
  HostListener,
  inject,
  input,
} from '@angular/core';
import { CATEGORY } from '@tokens/category.token';
import { CategoryType } from '@type/categories.type';

@Component({
  selector: 'app-base-tab',
  template: '',
  styleUrls: ['./base-tab.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export abstract class BaseTabComponent {
  private elRef = inject(ElementRef);

  protected activeCategory = inject(CATEGORY);

  element = this.elRef.nativeElement as HTMLElement;

  category = input<CategoryType>();

  isActive = computed(() => !!this.category()?.id && this.activeCategory.value()?.id === this.category()?.id);

  @HostBinding('style.height')
  get height() {
    return getComputedStyle(this.elRef.nativeElement).getPropertyValue('--page-tab-bar-height');
  }

  @HostBinding('class')
  cssClass = 'page-tab';

  abstract clickHandler(event: Event): void;

  @HostListener('click', ['$event'])
  click(event: Event) {
    this.clickHandler(event);
  }
}
