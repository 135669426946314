import { DOCUMENT } from '@angular/common';
import { inject, Injectable } from '@angular/core';
import { COMPANY_ID, RETURN_URL } from '@constants/search-params';
import { environment } from '@environments/environment';
import { TokenService } from '@services/token/token.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private tokenService = inject(TokenService);
  private defaultView = inject(DOCUMENT).defaultView;

  async toLoginPage(navigateToProjectRoot: boolean = false) {
    const companyId = localStorage.getItem(COMPANY_ID) || '';
    this.logOut();
    let returnUrl = '';

    if (companyId) {
      if (navigateToProjectRoot) {
        returnUrl = window.location.origin;
      } else {
        returnUrl = window.location.href;
      }

      const url = new URL(environment.loginUrl);
      url.searchParams.set(RETURN_URL, returnUrl);
      url.searchParams.set(COMPANY_ID, companyId);

      this.defaultView && this.defaultView.location.replace(url);
    }
  }

  logOut(): void {
    this.tokenService.removeToken();
  }
}
